// import {env} from "@/configs";
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/client/core";
import useAuth from "@/use/useAuth";
import {setContext} from "@apollo/client/link/context";
import {createUploadLink} from "apollo-upload-client/public";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";

const authLink = setContext((_, {headers}) => {
    const { getAccessToken } = useAuth()
    const token = getAccessToken()

    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null
        }
    }
})

const httpLink = ApolloLink.split(
    operation => operation.getContext().hasUpload,
    createUploadLink({
        uri: process.env.VUE_APP_CMS_GRAPHQL_URL,
    }),
    new BatchHttpLink({
        uri: process.env.VUE_APP_CMS_GRAPHQL_URL,
        fetch
    })
)

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const cmsClient = new ApolloClient({
    link: ApolloLink.from([authLink.concat(httpLink)]),
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        }
    }
})


export default cmsClient
