import {reactive, toRefs} from "vue";
import {useQuery, useResult} from "@vue/apollo-composable";
import { GET_ALL_USER } from "@/graphql/queries/userQuery";

const userStates = reactive({
    currentUser: null,
    currentPermissions: [],
})

export function useUser() {
    const setCurrentUser = (user) => {
        userStates.currentUser = {...user}
    }

    const can = (key) => userStates.currentPermissions.includes(key)

    const setCurrentPermissions = (permissions) => {
        userStates.currentPermissions = permissions
    }

    const setCurrentUserData = (payload) => {
      const keys = Object.keys(payload);
      keys.forEach((key, index) => {
        localStorage.setItem(key, payload[key]);
      })
    }

    const clearCurrentUser = () => {
        userStates.currentUser = null
        userStates.currentPermissions = []
    }

    return {
        ...toRefs(userStates),
        setCurrentUser,
        can,
        setCurrentPermissions,
        clearCurrentUser,
        setCurrentUserData
    }
}

export const fetchAllUsers = () => {
    const {result, loading, error, refetch} = useQuery(
        GET_ALL_USER,
        null,
        {
            clientId: 'cmsClient'
        }
    );

    const users = useResult(result, null, data => data.users)

    return {
        users,
        loading,
        error,
        refetch
    }
}
