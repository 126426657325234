<template>
  <router-view v-slot="{ Component }">
    <transition>
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
 @import url('./assets/styles/_main.scss');
</style>
