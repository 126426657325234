import {env} from "@/configs";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client/core";

const getHeaders = () => {
    const headers = {};
    const token = env.GITLAB_ACCESS_TOKEN;
    if (token) {
        headers.authorization = `Bearer ${token}`;
    }
    return headers;
};

// Create a new HttpLink to connect to your GraphQL API.
// According to the Apollo docs, this should be an absolute URI.
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: env.GITLAB_GRAPHQL_URL,
    fetch,
    headers: getHeaders(),
    assumeImmutableResults: true
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const gitLabClient = new ApolloClient({
    link: httpLink,
    cache,
})

export default gitLabClient
