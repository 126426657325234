export const env = {

  // GITLAB_ACCESS_TOKEN: 'yVt6rvMyFQJoH6mHsrvb',
  // GITLAB_PRIVATE_TOKEN: 'yVt6rvMyFQJoH6mHsrvb',

  GITLAB_ACCESS_TOKEN: 'hQJs5H8hU783SmxUymhb',
  GITLAB_PRIVATE_TOKEN: 'hQJs5H8hU783SmxUymhb',

  GITLAB_API_URL: 'https://gitlab.vietswiss.io/api/v4',
  GITLAB_GRAPHQL_URL: 'https://gitlab.vietswiss.io/api/graphql',
  PRISMIC_API_URL: 'https://vietswiss.cdn.prismic.io/api/v2',
  PRISMIC_ACCESS_TOKEN: 'MC5YVF84WGhFQUFDRUE0VFhp.DA7vv73vv71-77-9YTAyLjwx77-9Xgvvv73vv73vv71wJO-_vQdlC--_vTrvv73vv71ITO-_ve-_vQ',
  STORAGE_KEY: 'vs_gitlab',
}
