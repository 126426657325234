import {computed, ref} from "vue";

export default function () {
    const _isAuthenticated = ref(false)

    function setToken(token) {
        localStorage.setItem('access_token', token);
    }

    function getAccessToken() {
        return localStorage.getItem('access_token');
    }

    function clearToken() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('avatar_url');
    }

    function setAuthenticated() {
        _isAuthenticated.value = !!getAccessToken()
    }

    return {
        isAuthenticated: computed(() => _isAuthenticated.value),
        setToken,
        getAccessToken,
        clearToken,
        setAuthenticated
    }
}
