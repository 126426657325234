import {createApp, h, provide} from 'vue'
import App from './App.vue'
import router from './router'
// import {env} from './configs'

// createApp(App).mount('#app')
import Unicon from 'vue-unicons'
import { uniUser, uniUserCircle, uniApps, uniFolder, uniUsersAlt, uniBag, uniChart, uniEnvelope, uniKeySkeleton, uniBell, uniSearch, uniPause, uniCheck, uniPauseCircle, uniPlay, uniStopCircle, uniMultiply, uniListUl,  uniPlus, uniSignOutAlt } from 'vue-unicons/dist/icons'

Unicon.add([ uniUser, uniUserCircle, uniApps, uniFolder, uniUsersAlt, uniBag, uniChart, uniEnvelope, uniKeySkeleton, uniBell, uniSearch, uniPause, uniCheck, uniPauseCircle, uniPlay, uniStopCircle, uniMultiply, uniListUl,  uniPlus, uniSignOutAlt ])

import {ApolloClients} from "@vue/apollo-composable";

import { VueShowdownPlugin } from 'vue-showdown';

import cmsClient from "@/apollo/cmsClient";
import gitLabClient from "@/apollo/gitlabClient";
import moment from 'moment'


import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';

// Prism
import Prism from 'prismjs';
// highlight code
import 'prismjs/components/prism-json';

VMdEditor.lang.use('en-US', enUS);
VMdEditor.use(vuepressTheme, {
  Prism,
});

const app = createApp({
    setup() {
        provide(ApolloClients, {
            default: gitLabClient,
            cmsClient
        });
    },
    render: () => h(App),
});

app.use(router);
app.use(Unicon);
app.use(VueShowdownPlugin, {
    options: {
      emoji: true,
    },
  });

app.use(VMdEditor);

app.config.globalProperties.$filters = {
    convertDuration(seconds) {
        let hours = parseFloat(seconds / 3600)
        let str = hours.toFixed(2)
        return str
    },

    convertDateTime(data, format='DD.MM.YYYY') {
        return moment(data).format(format)
    }
}



app.mount("#app");
