import {createRouter, createWebHistory} from 'vue-router'
import useAuth from "@/use/useAuth";
import {GET_CURRENT_USER} from "@/graphql/queries/userQuery";
import cmsClient from "@/apollo/cmsClient";
import { useUser } from "@/use/useUser";

const lazyLoadView = view => () => import(`@/views/${view}.vue`)
const lazyLoadTempl = view => () => import(`@/tmpl/${view}.vue`)
const lazyLoadComp = view => () => import(`@/components/${view}.vue`)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: lazyLoadTempl('Layout'),
        redirect: 'dashboard',
        meta: {
            requiresAuth: true,
            permissions: ['dashboard-view']
        },
        children: [
            {
                path: '',
                name: 'dashboard',
                component: lazyLoadView('Dashboard'),
                meta: {
                    requiresAuth: true,
                    permissions: ['dashboard-view']
                },
                children: [
                    {
                        path: '',
                        name: 'Admin',
                        component: lazyLoadComp('dashboard/admin/index'),
                        meta: {requiresAuth: true},
                    },
                    {
                        path: '/',
                        name: 'Team Detail',
                        component: lazyLoadComp('teams/Index'),
                        meta: {requiresAuth: true},
                    }
                ]
            },
            {
                path: '/sample',
                name: 'Sample',
                component: lazyLoadView('Sample'),
                meta: {requiresAuth: true},
            },
            {
                path: '/team',
                name: 'Team',
                component: lazyLoadView('Team'),
                meta: {
                    requiresAuth: true,
                    permissions: ['team-view']
                },
                children: [
                    {
                        path: '',
                        name: 'Team Listing',
                        component: lazyLoadComp('teams/Listing'),
                        meta: {requiresAuth: true},
                    },
                    {
                        path: ':id',
                        name: 'Team Detail',
                        component: lazyLoadComp('teams/Index'),
                        meta: {requiresAuth: true},
                    }
                ]
            },
            {
                path: '/tasks',
                name: 'Tasks',
                component: lazyLoadView('Tasks'),
                meta: {
                    requiresAuth: true,
                    permissions: ['team-view']
                },
                children: [
                    {
                        path: '',
                        name: 'Tasks Listing',
                        component: lazyLoadComp('tasks/Listing'),
                        meta: {requiresAuth: true},
                    }
                ]
            },
            {
                path: '/projects',
                name: 'Projects',
                component: lazyLoadView('Projects'),
                meta: {
                    requiresAuth: true,
                    permissions: ['project-view']
                },
                children: [
                    {
                        path: '',
                        name: 'Projects Listing',
                        component: lazyLoadComp('projects/Listing'),
                        meta: {requiresAuth: true},
                    },
                    {
                        path: ':slug',
                        name: 'project-detail',
                        component: lazyLoadComp('projects/Project'),
                        props: (route) => ({path: route.params.path}),
                        meta: {requiresAuth: true},
                    }
                ]
            },
            {
                path: '/clients',
                name: 'Clients',
                component: lazyLoadView('Clients'),
                meta: {
                    requiresAuth: true,
                    permissions: ['client-view']
                },
                // children: [
                //   {
                //     path: '',
                //     name: 'Client Listing',
                //     component: lazyLoadComp('projects/Listing')
                //   }
                // ]
            },
            {
                path: '/reports',
                name: 'Reports',
                component: lazyLoadView('Reports'),
                meta: {
                    requiresAuth: true,
                    permissions: ['report-view']
                },
                children: [
                  {
                    path: '',
                    name: 'report',
                    component: lazyLoadComp('reports/index')
                  }
                ]
            },
            {
                path: '/profile',
                name: 'Profile',
                component: lazyLoadView('Profile'),
                meta: {
                    requiresAuth: true,
                    permissions: ['profile-view']
                }
            },

        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: lazyLoadView('Login')
    }
]

const router = createRouter({history: createWebHistory(), routes})

router.beforeEach((to, from, next) => {
    const {currentUser, currentPermissions, setCurrentUser, setCurrentPermissions} = useUser()

    // Check if the user is logged in
    const { setAuthenticated, isAuthenticated, clearToken } = useAuth()

    setAuthenticated()

    // Redirect to home if authenticated user try to visit login
    if (isAuthenticated.value && to.path === '/login') {
        next({
            path: '/'
        })
    }

    // Check if user is authenticated
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (!isAuthenticated.value) {
            next({
                path: '/login',
            })

        } else {
            // Get & set permissions when already logged in
            if (!currentUser.value) {

               cmsClient.query({
                    query: GET_CURRENT_USER,
                }).then( ({ data, errors }) => {

                    if ( data.me ) {
                        setCurrentUser(data.me)
                        setCurrentPermissions(data.me.permissions)
                        if (to.meta.permissions && to.meta.permissions.length > 0) {
                            let isAllowed = currentPermissions.value.some(permission => to.meta.permissions.includes(permission))
                            if (!isAllowed) {
                                next({
                                    path: '/'
                                })
                            } else {
                                next()
                            }
                        } else {
                            next()
                        }

                    } else {
                        console.log(errors); // "Unauthenticated."
                        clearToken();
                        next({
                            path: '/login',
                        });
                    }

                });

            } else {
                // Check if user has permission to route
                if (to.meta.permissions && to.meta.permissions.length > 0) {
                    let isAllowed = currentPermissions.value.some(permission => to.meta.permissions.includes(permission))

                    if (!isAllowed) {
                        next({
                            path: '/'
                        })
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            }
        }
    } else {
        next()
    }
})

export default router
